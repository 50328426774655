import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Code404Component } from './code404/code404.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { UploadComponent } from './upload/upload.component';
import { FilesComponent } from './files/files.component';
import { WebsiteComponent } from './website/website.component';

const routes: Routes = [
  {
    path: 'website',
    component: WebsiteComponent
  },
  {
    path: 'signup',
    component: SignupComponent,
    pathMatch: 'full'
  },
  {
    path: 'admin',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'company',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'upload',
    component: UploadComponent,
    pathMatch: 'full'
  },
  {
    path: 'files',
    component: FilesComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    component: UploadComponent,
    pathMatch: 'full'
  },
  {
    path: '**',
    component: Code404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
