import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CognitoService } from '../service/cognito.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  private idToken: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private cognito: CognitoService
  ) {
    this.cognito.isAuthenticated()
      .then((res) => {
        //console.log(res)
        this.router.navigate(['/upload']);
        return
      })
      .catch((err) => {
        console.log(err);
      });
  }

  ngOnInit() {
    //this.idToken = this.route.snapshot.queryParamMap.get('id_token');
    console.log(this.route.snapshot.fragment)
    if (this.route.snapshot.fragment) {
      const match = this.route.snapshot.fragment.match(/id_token=([\w\/\~\+\-]+\.[\w\/\~\+\-]+\.[\w\/\~\+\-]+)/);
      if (match) {
        this.idToken = match[1];
      }
      this.location.replaceState('');
    }
    this.initForm();
  }

  initForm() {
    const s = 'Bearer ' + (this.idToken || '');
    console.log(s)
    this.loginForm = this.fb.group({
      'email': ['', Validators.required],
      'password': ['', Validators.required]
    });
  }

  onSubmitLogin(value: any) {
    console.log(`this.idToken: ${this.idToken}`)
    //const email = value.email, password = value.password;
    //this.cognito.login(email, password)
    //.then((result) => {
      //console.log(result)
      //this.router.navigate(['/upload']);
      //return
    //}).catch((err) => {
      //console.log(err);
    //});
  }
}
